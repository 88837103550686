import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CatCard } from "blocks-nextjs/blocks-react/components/Card/Card";
import { CatGridItem } from "blocks-nextjs/blocks-react/components/GridItem/GridItem";
import { CatIconBatteryFull } from "blocks-nextjs/blocks-react/components/Icons/BatteryFull/BatteryFull";
import { CatIconBox } from "blocks-nextjs/blocks-react/components/Icons/Box/Box";
import { CatIconTrendingDown } from "blocks-nextjs/blocks-react/components/Icons/TrendingDown/TrendingDown";
import { CatIconTrendingUp } from "blocks-nextjs/blocks-react/components/Icons/TrendingUp/TrendingUp";
import { CatList } from "blocks-nextjs/blocks-react/components/List/List";
import { CatListItem } from "blocks-nextjs/blocks-react/components/ListItem/ListItem";
import { CatStatusIndicator } from "blocks-nextjs/blocks-react/components/StatusIndicator/StatusIndicator";
import { CatTable } from "blocks-nextjs/blocks-react/components/Table/Table";
import { CatTableBody } from "blocks-nextjs/blocks-react/components/TableBody/TableBody";
import { CatTableCell } from "blocks-nextjs/blocks-react/components/TableCell/TableCell";
import { CatTableHeader } from "blocks-nextjs/blocks-react/components/TableHeader/TableHeader";
import { CatTableHeaderCell } from "blocks-nextjs/blocks-react/components/TableHeaderCell/TableHeaderCell";
import { CatTableRow } from "blocks-nextjs/blocks-react/components/TableRow/TableRow";
import { CatTooltip } from "blocks-nextjs/blocks-react/components/Tooltip/Tooltip";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useProjectListQuery } from "../../services/query/project-asset-details.query";
import { assetOrderByOptions, httpStatus } from "../../shared/constants";
import { encodeProjectDetailsQuery } from "../../shared/helper";
import useProjectsListStore from "../../store/useProjectsListStore";
import useGlobalStore from "../../store/useGlobalStore";
import { FilterStatus, ProjectStatus } from "../Projects/ProjectSummaryEnum";
const ProjectSummary = (props) => {
    const [orderByProjectName, setOrderByProjectName] = useState("");
    const [hoveredPSWCount, setHoveredPSWCount] = useState(null);
    const [hoveredBABCount, setHoveredBABCount] = useState(null);
    const [isCatBatteryIconVisible, setisCatBatteryIconVisible] = useState(false);
    const [isCatBoxIconVisible, setisCatBoxIconVisible] = useState(false);
    const [hoveredProjectId, sethoveredProjectId] = useState("");
    const navigate = useNavigate();
    const { setProjectsList } = useProjectsListStore((state) => ({
        setProjectsList: state.setProjectsList
    }));
    const { userPartyNumber } = useGlobalStore((state) => ({
        userPartyNumber: state.userPartyNumber
    }));
    const queryParams = {
        ...(orderByProjectName && { projectName: orderByProjectName }),
        ...(userPartyNumber && { partyNumber: userPartyNumber })
    };
    const { data: { projects = [] } = {}, error, isError } = useProjectListQuery(encodeProjectDetailsQuery(queryParams), userPartyNumber);
    useEffect(() => {
        //Wrapping this in setTimeout without any delay helps us finding the shadow-root element
        setTimeout(() => {
            //Finding the shadow-root element to modify the styles of the table row
            const projectDetailsTableRowElement = document
                .querySelector(".summary-table-row")
                ?.shadowRoot.querySelector(".cat-c-table-row");
            projectDetailsTableRowElement?.setAttribute("style", "background-color: #ffffff");
        });
    });
    useEffect(() => {
        setProjectsList(projects);
    }, [projects?.length > 0]);
    const filteredProjectData = projects.filter((project) => {
        switch (props.filter) {
            case FilterStatus.All:
                return true;
            case FilterStatus.Active:
                return project.projectStatus.toLowerCase() === ProjectStatus.active;
            case FilterStatus.Closed:
                return project.projectStatus.toLowerCase() === ProjectStatus.closed;
            case FilterStatus.Pending:
                return project.projectStatus.toLowerCase() === ProjectStatus.pending;
            default:
                return false;
        }
    });
    const getURL = (projectStatus, projectId) => {
        switch (projectStatus.toLowerCase()) {
            case ProjectStatus.active:
                return `/project-details/${projectId}`;
            case ProjectStatus.pending:
                return "/new-project";
            case ProjectStatus.closed:
                return "";
            default:
                return "";
        }
    };
    const handleMouseEnter = () => {
        setisCatBatteryIconVisible(true);
    };
    const handleMouseLeave = () => {
        setisCatBatteryIconVisible(false);
    };
    const handleMouseEnter2 = () => {
        setisCatBoxIconVisible(true);
    };
    const handleMouseLeave2 = () => {
        setisCatBoxIconVisible(false);
    };
    const setESSCountAndProjectId = (count, id) => {
        if (count === null) {
            setHoveredPSWCount(null);
            setHoveredBABCount(null);
            sethoveredProjectId(id);
            return;
        }
        count?.forEach((element) => {
            if (element.assetMake === "PSW") {
                setHoveredPSWCount(element.count);
            }
            if (element.assetMake === "BAB") {
                setHoveredBABCount(element.count);
            }
        });
        sethoveredProjectId(id);
    };
    const gettingESSCount = (count, id) => {
        let totalCount = 0;
        count?.forEach((element) => {
            if (element.assetMake === id) {
                totalCount = totalCount + element.count;
            }
        });
        return totalCount;
    };
    const storageAssetCount = (essCount) => {
        let totalCount = 0;
        essCount?.map((ess) => {
            totalCount = totalCount + ess.count;
        });
        return totalCount;
    };
    if (isError && error?.response?.status === httpStatus.ERROR_403) {
        navigate("/restricted-access");
    }
    const CatStatusIndicatorStatus = (item) => {
        if (item.projectStatus.toLowerCase() === ProjectStatus.active) {
            return _jsx(CatStatusIndicator, { text: "Active", variant: "active" });
        }
        return _jsx(CatStatusIndicator, { text: "Pending", variant: "warning" });
    };
    return (_jsx(_Fragment, { children: props.selectedView === "tile" ? (_jsx("div", { "data-test": "project-summary-card", children: _jsx("div", { className: "flex flex-wrap", children: filteredProjectData.map((item, index) => (_jsx("div", { className: "w-full sm:w-1/2 px-2 py-2", children: _jsx(Link, { state: item.projectStatus.toLowerCase() === ProjectStatus.pending
                            ? { project: item }
                            : null, to: getURL(item.projectStatus, item.projectId), children: _jsx(CatGridItem, { "data-test": `${item.projectName}-project-card`, children: _jsxs(CatCard, { children: [_jsxs("div", { className: "flex justify-between mb-2", "data-test": `${item.projectName}-project-selection`, onMouseLeave: () => setESSCountAndProjectId(null, null), onMouseOver: () => setESSCountAndProjectId(item.essCount, item.projectId), children: [_jsxs("div", { children: [_jsx("h1", { className: "text-xl font-bold underline text-blue-600", "data-test": `${item.projectName}-project-name`, children: item.projectName }), _jsx("small", { "data-test": `${item.projectName}-project-owner`, children: item.owner })] }), _jsxs("div", { className: "flex space-x-2 relative hover:", "data-test": "asset-icons", children: [gettingESSCount(item.essCount, "PSW") !== 0 && (_jsxs(_Fragment, { children: [_jsx(CatIconBatteryFull, { color: "content-strong", "data-test": `${item.projectName}-battery-full`, "data-tooltip-id": "PSW", onMouseLeave: () => handleMouseLeave(), onMouseOver: () => handleMouseEnter(), size: "lg" }), isCatBatteryIconVisible &&
                                                                hoveredProjectId === item.projectId && (_jsxs(CatTooltip, { className: "left-[20px] absolute top-[-10px]", "data-test": "tool-tip-PSW", isActive: isCatBatteryIconVisible, position: "top-end", children: ["PSW Assets: ", hoveredPSWCount] }))] })), gettingESSCount(item.essCount, "BAB") !== 0 && (_jsxs(_Fragment, { children: [_jsx(CatIconBox, { color: "content-strong", "data-test": `${item.projectName}-icon-box`, "data-tooltip-id": "BAB", onMouseLeave: () => handleMouseLeave2(), onMouseOver: () => handleMouseEnter2(), size: "lg" }), isCatBoxIconVisible &&
                                                                hoveredProjectId === item.projectId && (_jsxs(CatTooltip, { className: "absolute top-[-10px]", "data-test": "tool-tip-BAB", isActive: isCatBoxIconVisible, position: "top-end", style: {
                                                                    left: gettingESSCount(item.essCount, "PSW") === 0
                                                                        ? "20px"
                                                                        : "52px"
                                                                }, children: ["BAB Assets: ", hoveredBABCount] }))] }))] })] }), _jsx("div", { className: "mb-2 text-base break-words", "data-test": `${item.projectName}-street-address`, children: item.streetAddress }), item.pastWeekEnergyConsumed && item.energyTrend ? (_jsxs("div", { className: "flex flex-row text-sm", children: [_jsx("div", { "data-test": `${item.projectName}-energy-to-ess`, children: "Energy to ESS:" }), _jsxs("div", { className: "flex items-center mb-2 text-sm space-x-2", children: [_jsxs("div", { className: "ml-[5px]", "data-test": `${item.projectName}-energy-consumed-last-week`, children: ["Last 7 days", " ", _jsxs("strong", { "data-test": `${item.projectName}-energy-consumed-last-week-value`, children: [Math.round(item.pastWeekEnergyConsumed), " kWh"] })] }), item.energyTrend < 0 ? (_jsx(CatIconTrendingDown, { color: "content-strong", "data-test": `${item.projectName}-trending-down`, size: "sm" })) : (_jsx(CatIconTrendingUp, { color: "content-strong", "data-test": `${item.projectName}-trending-up`, size: "sm" })), _jsxs("span", { className: `utility-success text-center ${item.energyTrend < 0 ? "text-green-600" : "text-red-600"}`, "data-test": `${item.projectName}-energy-trend`, children: [item.energyTrend.toFixed(2), "%"] })] })] })) : (_jsx("div", { className: "flex items-center mb-2 text-sm space-x-2", "data-test": `${item.projectName}-energy-trend-na` })), _jsxs("div", { children: [_jsx("span", { className: "text-xs", "data-test": `${item.projectName}-status`, children: "status:" }), item.projectStatus.toLowerCase() === ProjectStatus.active ||
                                                item.projectStatus.toLowerCase() === ProjectStatus.pending ? (CatStatusIndicatorStatus(item)) : (_jsx(CatStatusIndicator, { "data-test": `${item.projectName}-project-status-closed`, text: "Closed" }))] })] }, index) }) }) }, index))) }) })) : (_jsx("div", { className: "mt-1.5 ml-5", children: _jsx(React.Fragment, { children: _jsxs(CatTable, { arialabel: "Default Table", "data-test": "projects-list-view", children: [_jsx("div", { slot: "caption", children: "Default Table Description" }), _jsx(CatTableHeader, { "data-test": "projects-list-view-header-container", children: _jsxs(CatTableRow, { className: "summary-table-row", "data-test": "projects-list-header-row", children: [_jsxs(CatTableHeaderCell, { behavior: "filter", "data-test": "project-name-header-column", width: "200px", children: ["Project Name", _jsx(CatList, { slot: "filter", children: assetOrderByOptions.map((data) => {
                                                    return (_jsx(CatListItem, { onClick: (event) => {
                                                            event.stopPropagation();
                                                            setOrderByProjectName(data?.name);
                                                        }, children: data.value }, `project-name-orderby-${data.name}`));
                                                }) })] }), _jsx(CatTableHeaderCell, { "data-test": "status-header-column", width: "120px", children: "Status" }), _jsx(CatTableHeaderCell, { "data-test": "assets-header-column", width: "100px", children: "Assets" }), _jsx(CatTableHeaderCell, { "data-test": "location-header-column", width: "250px", children: "Location" }), _jsx(CatTableHeaderCell, { "data-test": "storage-assets-header-column", width: "200px", children: "Storage Assets" }), _jsx(CatTableHeaderCell, { "data-test": "owner-header-column", width: "150px", children: "Owner" }), _jsx(CatTableHeaderCell, { "data-test": "total-kwh-header-column", width: "150px", children: "Total kWh" })] }) }), _jsx(CatTableBody, { children: filteredProjectData.map((item, index) => (_jsxs(CatTableRow, { className: "cursor-pointer", onClick: () => {
                                    const url = getURL(item.projectStatus, item.projectId);
                                    if (item.projectStatus.toLowerCase() === ProjectStatus.pending) {
                                        navigate(url, { state: { project: item } });
                                    }
                                    else {
                                        navigate(url);
                                    }
                                }, children: [_jsx(CatTableCell, { align: "flex-start", width: "200px", children: _jsx("span", { className: "underline text-blue-600 inline-flex items-center", "data-test": `${item.projectName}-project-name`, children: item.projectName }) }), _jsx(CatTableCell, { align: "flex-start", width: "120px", children: item.projectStatus.toLowerCase() === ProjectStatus.active ? (CatStatusIndicatorStatus(item)) : (_jsx(CatStatusIndicator, { "data-test": `${item.projectName}-closed`, text: "Closed" })) }), _jsx(CatTableCell, { align: "flex-start", "data-test": `${item.projectName}-asset-count`, width: "100px", children: item.assetCount }), _jsx(CatTableCell, { align: "flex-start", className: "break-all", width: "250px", children: _jsx("span", { className: "underline text-blue-600", "data-test": `${item.projectName}-street-address`, children: item.streetAddress }) }), _jsx(CatTableCell, { align: "flex-start", "data-test": `${item.projectName}-storage-assets`, width: "200px", children: storageAssetCount(item.essCount) }), _jsx(CatTableCell, { align: "flex-start", "data-test": `${item.projectName}-owner`, width: "150px", children: item.owner }), _jsx(CatTableCell, { align: "flex-start", width: "150px", children: _jsx("div", { className: "inline-flex items-center", children: _jsxs("span", { "data-test": `${item.projectName}-kwh`, children: ["Energy to ESS: Last 7 days", " ", _jsxs("strong", { "data-test": `${item.projectName}-last-week-energy-consumed-value`, children: [Math.round(item.pastWeekEnergyConsumed), " kWh"] })] }) }) })] }, index))) })] }) }, "123456789") })) }));
};
export default ProjectSummary;
